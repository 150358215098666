import { LanguageSwitcherButton } from './components/language-switcher-button';
import { getLocaleFromUrl, getPathnameFromUrl, useTranslatedPath } from '@/i18n';
import { useUrl } from '@/stores/url';
import { setField } from '@/utils/persistence';

export default function LanguageSwitcherDesktop() {
  const url = useUrl();
  const lang = getLocaleFromUrl(url);
  const pathname = getPathnameFromUrl(url);
  const translatePath = useTranslatedPath(lang);

  const labels = { en: 'Switch to English', id: 'Ganti ke Bahasa' };

  return (
    <div className="hidden items-center lg:flex">
      <LanguageSwitcherButton
        className="border-r-none rounded-r-none"
        href={translatePath(pathname, 'id')}
        isActiveLocale={lang === 'id'}
        label={labels.id}
        onClick={() => setField('lang', 'id')}
      >
        ID
      </LanguageSwitcherButton>
      <LanguageSwitcherButton
        className="border-l-none rounded-l-none"
        href={translatePath(pathname, 'en')}
        isActiveLocale={lang === 'en'}
        label={labels.en}
        onClick={() => setField('lang', 'en')}
      >
        EN
      </LanguageSwitcherButton>
    </div>
  );
}
